/* Import the Roboto font */
@import url('https://fonts.googleapis.com/css2?family=Roboto');
@import url('https://fonts.google.com/specimen/Roboto+Serif');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab');


#formSearch:focus .form-control .input-group {

}

.input-group {
  outline: none !important;
  height: 36px;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 250ms ease-in;
}


a, button, input, textarea, form {
  outline: none;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

#root {
  padding: 0px;
  margin: 0px;
}

.App {
  width: auto;
  display: flex; 
  /* Enables Flexbox */
  text-align: center;
  max-width: 1800px;
  min-width: 393px;
}


.float-right {
  float: right;
}

.copyright-footer {
  margin: 20px;
  font-size: 1rem;
  text-align: left;
  line-height: 1.1;

}

.App-header {
  background-color: #D8E7EF;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

body {
  margin: 0;
  background-color: #fff;
}

.responsive-image {
  width: 90%;
  margin: 0px auto; /* Adjusted for horizontal centering */
  padding: 0px;
  margin-right: 20px;
  margin-bottom: 10px;
  height: auto;
  display: block; /* Optional: Ensures the image does not have extra space below it */
}

a.external-link::after {
  content: '\f35d';
  margin-left: 4px;
  font-family: 'Font Awesome 5 Free', 'Font Awesome 5 Brands';
  font-weight: 900; /* Font weight for solid icons */
  font-size: small;
  color: #3783B2;
}

a.external-link {
  font-weight: 500;
  color: #000;
  text-decoration: none;
}
a.external-link span {
  text-decoration: underline;
}

a.external-link:active {
  color: #3783B2;
}

.grid-header p a:visited {
  color: #3783B2; 
}


.search-label {
  border: 2px solid #D8E7EF;
  display: flex;        /* Enable Flexbox */
  align-items: center;  /* Vertically center the content */
  justify-content: center; /* Horizontally center the content, if needed */
  font-family: 'Roboto', sans-serif;
  font-size: 0.8rem;
  /* text-transform: uppercase; */
  padding-bottom: 6px;
  padding-top: 6px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 10px;
  font-weight: 500;
  color: #3783B2;
  background-color: #E9F3F8;
  width: auto;
  float: right;
}

.page-item {
  border-color: #A1A2A2;
  color: #3783B2;
}

/* .page-item active {
  background-color: #b2377b;
} */



.search-pagination .active>.page-link {
  background-color: #E9F3F8; /* Replace #yourColor with the color you want */
  color: #3783B2;
  border-color: #D8E7EF /* Optionally, change the border color as well */
}



.page-link {
  color: #3783B2;

}

.modal-body {
  padding: 40px;
}

.modal-body p {
  margin-bottom: 20px;
}

.content-middle {
  padding: 0px;
margin: 0px

}

.container {
  max-width: 99%;
  margin: 0px;
  padding: 0px;
}

.search-icon {
  height: 24px;
  width: auto;
}

#table-of-contents {
  max-width: 300px;
  margin-left: 0px;
  height: auto;
  padding-right: 2px;
  text-align: left;
  top: 0;
}

.input-group-text {
  margin-right: 10px;
  margin-left: 0px;
  padding-left: 0px;
  padding-right: 0px;
  border: none;
  border-color: #fff;
  background-color:  #Fff;
  font-size: 1.4rem;
  color: #3783B2;
  font-weight: 500;
}

#formSearch {
  color: #3783B2;
  border: none;
  border-radius: 0px;
  padding: 0px;
  padding-left: 6px;
  font-size: 1.4rem;
  font-weight: 500;
}

.form-control {
  color: #3783B2;
}

.nav-link {
  font-weight: 500;
}

#form-control :placeholder-shown {
  color: #3783B2;
  border: none;
  border-radius: 0px;
  padding: 2px;
  padding-left: 6px;
  font-size: 1.2rem;
}

.filter-faqs-container {
display: flex;
flex-direction: row; 
align-items: center;
margin-top: 10px;
margin-bottom: 10px;
flex-wrap: wrap; 
}

.filter-faqs-container p {
  font-family: 'Roboto', sans-serif;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 500;
  margin-right: 20px;
  }
  
.filter-faqs {
  flex-direction: row; 
  padding-right: 16px;
  text-align: left;
}

.filter-faqs label {
  align-items: center;
  font-size: 1.2rem;
  font-weight: 300;
}

.filter-faqs input[type="checkbox"] {
  width: 16px;
  background-color: #fff;
  height: 16px; 
  margin-right: 5px; 
  align-items: center;
}


input[type='checkbox']:checked {
  background-color: #b2377b; 
}

/* .filter-faqs input[type="checkbox"]:checked {
 
} */


#cards {
  flex-grow: 1; /* This container will take up the remaining space */
  padding-top: 20px;
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
}

.product-support {
  width: 340px; /* Fixed width for the product support */
  margin-left: 10px;
  margin-top: 100px;
  
}

.product-support-container {
  width: 340px;
  height: auto;
  margin: 0px;
  padding: 20px;
  background: #E9F3F8;
}

.product-support-header {
  height: 60px;
  align-items: center; 
  padding: 10px;
  background-color: #4BADE9;
  display: flex; /* Use flexbox for layout */
}

.product-support-image {
  width: 40px; /* Fixed width for the image */
  height: auto; /* Maintain aspect ratio */
  margin-right: 10px;
  padding: 4px;
}

.sticky-button {
  position: fixed;  /* Fixed position relative to the viewport */
  right: 10px;      /* Positioned to the right of the viewport */
  bottom: 15%;     /* Positioned at the bottom of the viewport */
  z-index: 1000;    /* Ensure it's above other elements */
  box-shadow: 2px 2px 5px #ccc; 
  background-color: #4BADE9;
  border: 0px;
  border-top-left-radius: 10px;  /* Rounds the top-left corner */
  border-bottom-left-radius: 10px; 
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}

.sticky-button:hover {
background-color: #3783B2;
}

.product-support-header h4 {
  color: #fff;
  font-size: 1.4rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 800;
  margin: 0;
  padding: 0px;
  float: left;
  letter-spacing: 1px;


}
.product-support-form {
  text-align: left;
}

.product-support-form input {
  background-color: #ffffff;
  outline: none;
}

.product-support-form input:active {
  background-color: #ffffff;
  outline: none;
}

.product-support-form-button {
    text-align: right;
  }     

.product-support-form button{
margin-top: 20px;
 background-color: #4BADE9;
 border: 2px solid #fff;
}

.product-support-form button:hover{
  background-color: #3783B2;
  border: 2px solid #fff;
 }

.product-support-form button:disabled{
  background-color: #E9F3F8;
  color: #A1A2A2;
  border: 2px solid #fff;
 }

.feedback-message {
  width: 300px;
  font-size: 1.4rem;
  font-weight: 400;
  text-align: center;
  padding: 20px;
  line-height: 1.1;
  height: 300px;
}

.form-label {
  font-size: 1.1rem;
  font-weight: 400;
  margin: 0px;
  margin-bottom: 2px;
  padding: 0px;
  text-align: left;
}



.my-masonry-grid {
  display: flex;
  /* margin-left: -10px;  */
  margin-bottom: 0px;
  margin: 0px;
  width: auto;
  column-gap: 30px;
}

.card {
  /* margin-top: 20px;
  margin-bottom: 20px; */
}

.masonry-item {
  break-inside: avoid; /*added*/
  /* margin-bottom: 1rem; */
  padding: 0;
  margin: 0;
  /* margin-top: 20px; */
  margin-bottom: 30px;

}

/* All About CARDS */
.card-body {
  margin: 0;
  padding: 0;

}

.card-style-a {
  background-color: #ffffff; 
  border-radius: 0px;
  border: 6px double #fff;
  text-align: left;
  padding: 0px;
  margin: 0px;
}

.card-style-general {
  border-top: 16px solid #3783B2;
  box-shadow: 2px 2px 5px #ccc; /* Slight drop shadow */
  text-align: left;
  border-radius: 0px ;
  padding: 20px;
  margin: 0px;
  /* margin-top: 10px;
  margin-bottom: 20px; */
}

.grid-item {
  margin: 0px;
}

.card-style-simple {
  border: 0px;
  background-color: #fff;
  text-align: left;
  border-radius: 0px ;
  padding: 20px;
  margin: 0px;
  /* margin-top: 10px;
  margin-bottom: 20px; */
}

.logic-number {
  background-color: #fff;
  width: 34px;
  text-align: center;
  font-size: 1.2rem;
  border: 1px solid black; /* Corrected to 1px black border */
  padding: 32x;
  padding-left: 10px;
  padding-right:10px; 
  margin-right: 10px; 
  margin-bottom: 10px;
}

.card-style-simple-wide {
  border: 0px;
  background-color: #fff;
  text-align: left;
  border-radius: 0px ;
  padding: 0px;
  margin: 0px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.logic-table {
  display: flex;
  margin-right: 0px;
  margin-top: 10px;
  margin-bottom: 10px;
  flex-wrap: wrap; /* or nowrap if you don't want wrapping */
  gap: 8px; /* Adjust space between items */
}

.logic-item {
  font-family: 'Roboto', sans-serif;
  background: #F9F9F9; /* Light grey background */
  border: 1px solid #DDDDDD; /* Light grey border */
  padding: 18px;
  flex-basis: calc(50% - 6px);
  overflow: auto; /* To contain the floated element */
}

.logic-item h4 {
  margin: 0;
  margin-bottom: 4px;
  font-size: 1.4rem;
  font-weight: 700;
  color: #3783B2;
}

.logic-item p {
  margin: 0;
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 1.6;
}

.logic-item p.description {
  margin: 0;
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 1.2;
}

.logic-item quote {
  margin: 0;
  font-size: 1.3rem;
  font-style: italic;
  font-weight: 300;
  line-height: 1.2;
  margin: 10px;
  margin-left: 20px;
  float: left;
}


/* 
.my-masonry-grid_column {
  padding: 0;
} */

.card-style-lines {
  border: 6px double #D2D3D4;
  background-color: #fff;
  text-align: left;
  border-radius: 0px ;
  padding: 20px;
  margin: 0px;
  /* margin-top: 10px;
  margin-bottom: 20px; */
}

.card-style-lines h2 {
  font-size: 1.6rem;
  font-weight: 500;
  color: #000;
  /* font-style: italic; */
  margin-bottom: 20px;
}

.card-style-search {
  border: 6px double #D2D3D4;
  background-color: #fff;
  text-align: left;
  border-radius: 0px ;
  padding: 20px;
  margin: 0px;
  /* margin-top: 10px;
  margin-bottom: 20px; */
}

.card-style-search h2 {
  font-size: 1.9rem;
  font-weight: 500;
  color: #3783B2;
  /* font-style: italic; */
  margin-bottom: 20px;
}


.card-style-faq h2 {
  font-size: 1.9rem;
  font-weight: 500;
  color: #3783B2;
  font-style: italic;
  margin-bottom: 20px;
}

.question {
  font-family: 'Roboto', sans-serif;
  font-size: 1.0rem;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 6px;
}

.card-style-faq {
  border: 6px double #D2D3D4;
  background-color: #fff;
  text-align: left;
  border-radius: 0px ;
  padding: 20px;
  margin: 0px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.card-style-red-lines{
  border: 6px double #E12A2A;
  background-color: #fff;
  text-align: left;
  border-radius: 0px;
  padding: 20px;
  padding-top: 70px; /* Increased top padding to accommodate the image */
  margin: 0px;
  /* margin-top: 10px;
  margin-bottom: 0px; */
  position: relative; /* Needed for absolute positioning of the pseudo-element */
}

.card-style-red-lines:before {
  content: "";
  display: block;
  height: 40px; /* Height of the image */
  width: 44px;
  background-image: url('/public/img/bang.svg');
  background-size: cover; /* Resize the background image */
  position: absolute;
  top: 20px; /* Position from the top of the card */
  left: 20px; /* Position from the left of the card */
  z-index: 1;
}


.card-style-blue {
  /* border-radius: 20px; */
  background-color: #F3F9FB;
  text-align: left;
  padding: 20px;
  padding-top: 70px; /* Increased top padding to accommodate the image */
  margin: 0px;
  /* margin-top: 10px;
  margin-bottom: 20px; */
  box-shadow: 2px 2px 5px #A1A2A2; /* Slight drop shadow */
}

.card-style-blue::before {
  content: "";
  display: block;
  height: 40px; /* Height of the image */
  width: 40px;
  background-image: url('/public/img/info.svg');
  background-size: cover; /* Resize the background image */
  position: absolute;
  top: 20px; /* Position from the top of the card */
  left: 20px; /* Position from the left of the card */
  z-index: 1;
}



.card-style-blue-star {
  background-color: #ffffff;
  border: none;
  text-align: left;/* Adjust padding as needed */
  padding-top: 20px;
  padding-left: 60px;
  position: relative;
}

.card-style-blue-star::after {
  content: "";
  position: absolute;
  top: 16px;  /* Adjust position as needed */
  left: 10px; /* Adjust position as needed */
  width: 32px;  /* Adjust size as needed */
  height: 32px; /* Adjust size as needed */
  background-image: url('/public/img/star.svg');
  background-size: cover; /* Or 'contain' based on your need */
}

.card-style-red-arrow{
  border: none;
  text-align: left;/* Adjust padding as needed */
  padding: 20px;
  padding-left: 60px;
  padding-bottom: 0px;
  position: relative;
}

.card-style-red-arrow:after {
  content: "";
  position: absolute;
  top: 22px;  /* Adjust position as needed */
  left: 10px; /* Adjust position as needed */
  width: 36px;  /* Adjust size as needed */
  height: 28px; 
  background-image: url('/public/img/arrow-red.svg');
  background-size: cover; /* Or 'contain' based on your need */
}


.card-style-red-arrow li {
  padding-top: 10px;
  }


.card-style-blue-arrow {
  border: none;
  text-align: left;/* Adjust padding as needed */
  padding: 20px;
  padding-left: 60px;
  padding-bottom: 0px;
  position: relative;
}

.card-style-blue-arrow li {
padding-top: 10px;
}

.card-style-blue-arrow::after {
  content: "";
  position: absolute;
  top: 22px;  /* Adjust position as needed */
  left: 10px; /* Adjust position as needed */
  width: 36px;  /* Adjust size as needed */
  height: 28px; 
  background-image: url('/public/img/arrow-blue.svg');
  background-size: cover; /* Or 'contain' based on your need */
}



/* Navigation */

.table-of-contents {
  padding-right: 10px
}

.table-of-contents button {
  border: none;
  border-color: #fff;
  background-color: #fff;
  font-size: 1.4rem;
  color: #3783B2;
  font-weight: 500;
  padding: 0px;


}
.table-of-contents a:hover {
color: #000;
}

.table-of-contents .arrow {
  display: inline-block;
  font-size: 1.1rem;
  margin-left: 5px;
  padding-top: 2px;
  transition: transform 0.3s ease;
}

.table-of-contents .arrow.up {
  transform: rotate(180deg);
}

.table-of-contents .arrow.down {
  transform: rotate(0deg);
}

.table-of-contents ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

.table-of-contents ul li {
  font-size: 1.4rem;
  color: #3783B2;
  font-weight: 500;
  padding: 6px;
  border-bottom: 1px solid #ccc;
  margin-bottom: -1px;
}

.table-of-contents ul li a {
  text-decoration: none;
  color: #3783B2;
  display: block;
}

.table-of-contents ul li ul {
  padding-left: 20px;
  font-weight: 500;
}
.table-of-contents ul li ul li a {
  color: #000;
}

.table-of-contents ul li ul li a:hover {
  color: #3783B2;
}


.table-of-contents ul li ul li span {
  color: #3783B2;
}

.table-of-contents ul li ul li {
  color: #000;
  font-size: 1.4rem;
  font-weight: 400;
  padding: 2px;
  border-bottom: none;
}

.table-of-contents ul li a:hover {
  color: #000;
}

/* Active (Click) State */
.accordion ul li a:active {
  color: #000; /* Replace with your desired active color */
}

/* Selected State */
.table-of-contents ul li a.selected {
  color: #000; /* Replace with your desired selected color */
}


/* GRID */

.card-grid-container {
  border: 5px solid #3783B2;
  padding: 30px;
  padding-top: 30px;
}

.modal-title-custom {
  position: relative;
  display: block;
}

.modal-close-button {
  position: absolute;
  right: 10px; /* Adjust as per your design */
  top: 10px; /* Adjust as per your design */
  z-index: 10; /* Ensures the button is above the image */
}
.grid-header{
  background-color: #3783B2; /* Example background color */
  color: #fff; /* Example text color */
  width: 100%;
  text-align: left;
  padding: 20px;
  padding-left: 30px;
  margin-bottom: -4px;
  z-index: 400;
  display: flex; /* Set the container to be a flex container */
  align-items: center; /* Vertically center align the child elements */

}

.grid-header h2 {
  font-size: 1.6rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 800;
  margin: 0;
  padding: 0px;
  float: left;
  margin-right: 20px;
  letter-spacing: 1px;
}

.grid-header p {
  font-size: 1.1rem;
  line-height: 1.2;
  font-style: italic;
  font-family: 'Roboto', sans-serif;
  margin: 0px;
  float: left;
}




/* All About TEXT */

h1 {
  font-size: 2.4rem; /* Default size for h1 */
  color: #3783B2;
  font-weight: 500;
  line-height: 1.1;
}

h2 {
  font-size: 1.6rem; /* Default size for h2 */
  font-weight: 500;
}

h3 {
  font-size: 1.6rem; /* Default size for paragraph text */
  font-weight: 500;
}

p {
  font-family: 'Roboto Serif', serif;
  font-size: 1.4rem; /* Default size for paragraph text */
  font-weight: 300;
  line-height: 1.5;
  padding-bottom: 4px;
  margin: 0;
  padding: 0;
}



/* site header */

.site-header {
  display: flex;
  width: 100%;
  align-items: flex-start;
  /* padding-top: 100px; */
  padding: 10px;
  margin-bottom: 10px;
}

.user-manual {
  float: left; /* Floating left if needed, but flexbox handles positioning */
  font-weight: 400;
  line-height: 1.1;
  margin-right: 30px;
  margin-top: 8px;
  text-align: left;
}

.barcode {
  display: flex; /* Use flexbox for layout */
  align-items: center; /* Vertically center items in the container */
}

.barcode img {
  width: 240px;
  align-self: flex-end; /* Align image to the right */
  max-width: 100%; /* Ensures image is responsive */
  height: auto; /* Maintains aspect ratio */
  padding: 0px;
  margin: 0px;
  margin-bottom: 20px;
}


.header-info {
  text-align: left;
  display: flex; /* Use flexbox for layout */
  flex-direction: column; /* Stack items vertically */
  align-items: flex-start; /* Align text to the left */
  flex-grow: 1; /* Allow this div to take up available space */
}


.header-info h1 {
  font-family: 'Roboto', sans-serif;
  color: #000;
  font-size: 2.0rem;
  font-weight: 600;
  margin: 0;

}

.header-info .version {
  color: #000;
  font-family: 'Roboto', sans-serif;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 100;
  line-height: 34px;
}

.header-info .subtitle {
  color: #000;
  font-family: 'Roboto Serif', serif;
  font-size:  1.3rem;
  font-style: italic;
  font-weight: 300;
  line-height: 24px;

}


/* ideas footer */
.ideas-footer {
  text-align: left;        /* Align text to the left */
  width: 100%;             /* Make the block full width */
  padding: 10px;           /* Add some padding for better spacing */
  padding-bottom: 0px;
}

.ideas-footer p {
margin-right: 50px;
}


.additional-notes-header {
  margin-top: 40px;
  margin-bottom: 20px;
  border-bottom: 2px solid #3783B2;
  width: 100%; 
}

.process-data-header {
  text-align: left;        /* Align text to the left */
  width: 100%;             /* Make the block full width */
  padding: 10px;           /* Add some padding for better spacing */
  padding-bottom: 20x;

}


.process-data-header p {
  margin-right: 50px;
  }
  

.leading-people-header {
    text-align: left;        /* Align text to the left */
    width: 100%;             /* Make the block full width */
    padding: 0px;           /* Add some padding for better spacing */
    padding-bottom: 0px;
  }

  .leading-people-header masonry {
    width: 100%;
    margin: 0 auto;
  }

  .leading-people-description {
    width: 100%;
    font-size: 1.6rem;
    font-weight: 100;
    font-family: 'Roboto', sans-serif;
    line-height: 1.2;
    margin-bottom: 10px;
  }
 

.culture-table {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  flex-wrap: wrap; /* or nowrap if you don't want wrapping */
  gap: 6px; /* Adjust space between items */
}

.culture-item {
  background: #F2F2F2; /* Light grey background */
  border: 1px solid #DDDDDD; /* Light grey border */
  padding: 18px;
  flex-basis: calc(50% - 20px); /* Adjust this for different items per row */
}

.culture-item h4 {
  margin: 0;
  margin-bottom: 4px;
  font-size: 1.4rem;
  font-weight: 700;
}

.culture-item p {
  margin: 0;
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 1.3;
}

.culture-image {
  width: auto;
  height: 40px;
  margin-bottom: 20px;
}

.flex-container {
  display: flex;
  align-items: flex-start; /* Aligns items to the start of the flex container */
}

.text-column {
  flex: 1; /* Takes up the remaining space */
}

.cards-column {
  flex: 2; /* Takes up twice as much space as the text column */
}









/* brain beader */

.brain-header {
  text-align: left;        /* Align text to the left */
  width: 100%;             /* Make the block full width */
  padding: 10px;           /* Add some padding for better spacing */
  padding-bottom: 0px;
}


.brain-table {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  flex-wrap: wrap; /* or nowrap if you don't want wrapping */
  gap: 30px; /* Adjust space between items */
}

.brain-item {
  background: #fff; /* Light grey background */
  padding: 0px;
  flex-basis: calc(50% - 30px); /* Adjust this for different items per row */
  border-top: 4px solid #3783B2;
  padding-top: 20px;
  padding-left: 10px;
  margin-bottom: 20px;
}

.brain-item p {
  margin: 0;
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 1.3;
}

.brain-item h4 {
  margin: 0;
  margin-bottom: 4px;
  font-size: 1.4rem;
  font-weight: 700;
}

/* important warnings header */

.important-warnings-header {
  text-align: left;        /* Align text to the left */
  width: 100%;             /* Make the block full width */
  padding: 0px;           /* Add some padding for better spacing */
  padding-bottom: 0px;
}

.important-warnings-footer {
  text-align: left;        /* Align text to the left */
  width: 100%;             /* Make the block full width */
  padding: 10px;           /* Add some padding for better spacing */
  padding-bottom: 20px;
}

.important-warnings-footer p {
  margin-right: 50px;
  }

.warnings-table {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  flex-wrap: wrap; /* or nowrap if you don't want wrapping */
  gap: 6px; /* Adjust space between items */
}

.warnings-item {
  background: #F2F2F2; /* Light grey background */
  border: 1px solid #F2F2F2; /* Light grey border */
  padding: 12px;
  flex-basis: calc(33.333% - 10px); /* Adjust this for different items per row */
}

.warnings-item p {
  /* margin: 0;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.2; */

  margin: 0;
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 1.3;
}

.warnings-item h4 {
  margin: 0;
  margin-bottom: 4px;
  font-size: 1.4rem;
  font-weight: 700;
}

.failure-spectrum {
  font-size: 1.3rem;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
  margin-top: 30px;
}

.failure-spectrum-right{
  font-size: 1.3rem;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
  float: right;
  padding-right: 10px;
  /* margin-top: -20px; */

}

.flex-failure-spectrum-right {
  display: flex;
  justify-content: flex-end; /* Aligns the child to the right */
}

.accordion-card {
  padding: 0px;
  border: none;
  margin: 0px
}

.accordion h4 {
margin: 0px;
font-size: 1.4rem;
font-weight: 500;
margin-bottom: 4px;
padding: 0px;
color: #3783B2;

}
.accordion-failure {
  /* margin-bottom: 30px */
}

.accordion p {
    font-family: 'Roboto Serif', serif;
    font-size: 1.3rem; /* Default size for paragraph text */
    font-weight: 400;
    line-height: 1.5;
    margin: 0;
    padding: 0;
  }

.accordion-item {
  border-radius: 0;
  border: none;
  margin-bottom: 0px;
}

.accordion-button {
 border-radius: 0;
  padding: 0px;
  margin: 0px;
  padding-right: 30px;
  padding-bottom: 20px;
  padding-top: 20px;
}

.accordion-button:focus {
  background-color: #fff;
  border: none;
  outline: none;
  box-shadow: 0 0 0 2px #fff;
  
 }
.accordion-button:not(.collapsed) {
  background-color: #fff; 
  outline: none;
}

.button:focus:not(:focus-visible) {
  outline: none;
}

.accordion-button:active{
  background-color: #fff;
  border: none;
   }
  
.accordion-body {
  padding: 0px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.alert-heading {
  padding: 0px;
  margin: 0px;
}

.get-started-here {
  position: relative; /* Set the position to relative */
  height: 160px;
  background-color: #fff;
  padding: 0px;
  padding-left: 310px;
  border: none;
  background-image: url('/public/img/skinny-alert.svg');
  background-size: cover;
  border-right: 1.5px solid #000;
  border-radius: 0px;
  text-align: left;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: left;
  padding-right: 190px;
}

.hdmi {
  position: absolute; /* Use absolute positioning */
  float: right;
  margin-left: 10px;
  right: 30px; /* Align to the right within the container */
  width: auto;
  top: 40%; /* Center vertically */
  transform: translateY(-50%); /* Adjust for exact vertical centering */
}

.get-started-here h3 {
  font-size: 1.9rem; /* Default size for paragraph text */
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
  color: #000;
  text-align: left;
  padding-bottom: 0px;
  margin-bottom: 0px;
  line-height: 1.1;

}


.alert-primary p {
  font-size: 1.3rem;
  color: #000;
  text-align: left;
  padding: 0px;
  margin: 0px;
  line-height: 1.2; 
}

.alert-primary .btn-primary {
  background-color: #4BADE9;
  border: 2px solid #fff;
  box-shadow: 2px 2px 5px #A1A2A2; /* Slight drop shadow */
  margin-top: 8px;
  font-size: 1.1rem;
  line-height: 1.1;
  padding: 8px;
  border-radius: 20px;
  padding-left: 12px;
  padding-right: 12px;
  font-weight: 700;
}

.alert-primary .btn-primary:hover {
  background-color: #3783B2;
}

.alert-primary .btn-close {
  display: flex;
  align-items: center;
  width: 10px;
  padding: 0px;
  margin: 10px;
}

.clickable-alert {
  cursor: pointer;
}

#offcanvasNavbar-expand-lg {
  background-color: #fff;
  padding: 0px;
}

.offcanvas {
  padding: 10px;
  background-color: #F3F9FB;
}

.nav-header {

  padding-top: 180px;
  margin: 0px;
  background-image: url('/public/img/reyland-dot-com.jpg');
  background-size: 380px auto;
  background-repeat: no-repeat;
}


.mobile-nav-filters{
  background-color: none;
  padding: 10px;
  padding-top: 180px;
}

.offcanvas-content {
  padding: 20px;
  background-color: #fff;
  border: 2px solid #4BADE9;
}

.offcanvas-header {
  display: flex;
  justify-content: flex-end;
  height: 10px;
  margin: 0px;
}

.btn-close {
  float: right;
}

.navbar {
  width: 50px;
  padding: 0px;
  margin: 0px;
  float: right;
}

/* /////////////////////////////////// */
/* x-small */
@media screen and (max-width: 576px) {
.version {
  display: none;
}

.get-started-here {
  padding-left: 100px;
  margin: 0px;
  background-image: url('/public/img/get-started-here-mobile.png');
  background-size: contain; /* Or 'contain' based on your need */
  height: 160px;
}

.App {
  padding-left: 0px;
  padding-right: 0px;
}

.card-grid-container {
  border: 5px solid #3783B2;
  padding: 20px;
  padding-top: 30px;
}

}

/* small */
@media screen and (max-width: 767px) {
  
.header-info h1 {
  font-family: 'Roboto', sans-serif;
  color: #000;
  font-size: 1.6rem;
  font-weight: 900;
  margin: 0;
}

.card-grid-container {
  border: 5px solid #3783B2;
  padding: 20px;
  padding-top: 30px;
}

.user-manual {
  margin-right: 16px;
}
  
.get-started-here {
  display: none;
  }

/* .get-started-here {
  padding-left: 110px;
  background-image: url('/public/img/get-started-here-mobile.png');
  background-size: cover; 
  height: 160px;
  }

  .get-started-here h3{
    font-size: 1.7rem;
  }

  .get-started-here p{
    font-size: 1.2rem;
  } */

}
/* medium */
@media screen and (max-width: 991px) {
   
  .site-header {
    padding: 20px;
  }
  .card-grid-container {
    padding-top: 30px;
    border: 5px solid #3783B2;
    padding: 40px;
  }

  .get-started-here {
    padding-left: 200px;
    margin: 0px;
    margin-bottom: 30px;
    background-image: url('/public/img/skinny-alert.svg');
    background-size: cover; /* Or 'contain' based on your need */
    height: 100px;
  }

  .get-started-here h3{
    font-size: 1.7rem;
  }

  .logic-item  {
    flex-basis: 100%; /* Full width on screens less than 768px */
  }
  
  .warnings-item {
    flex-basis: calc(50% - 6px);
  }

  .card-grid-container {
    padding-top: 30px;
    padding: 20px;
  }

  .barcode img {
    display: none;
  }

  .search-form {
    display: none;
  }

  #table-of-contents, #product-support {
    display: none;
    z-index: 100;
  }
  .App { /* Assuming .App is the flex container */
    display: flex;
    flex-direction: column; /* Stack children vertically on smaller screens */
  }
  #content-middle {
    flex-grow: 1; /* Allow .content-middle to take up available space */
  }


  .card-style-blue-star {
    background-color: #ffffff;
    border: none;
    text-align: left;/* Adjust padding as needed */
    padding-top: 50px;
    padding-left: 0px;
    position: relative;
  }
  
  .card-style-blue-star::after {
    content: "";
    position: absolute;
    top: 8px;  /* Adjust position as needed */
    left: 0px; /* Adjust position as needed */
    width: 32px;  /* Adjust size as needed */
    height: 32px; /* Adjust size as needed */
    background-image: url('/public/img/star.svg');
    /* background-size: cover;  */
    /* Or 'contain' based on your need */
  }

  .card-style-blue-arrow{
    border: none;
    text-align: left;/* Adjust padding as needed */
    padding: 20px;
    padding-left: 10px;
    padding-top: 34px;
    position: relative;
  }
  
  .card-style-blue-arrow:after {
    content: "";
    position: absolute;
    top: 10px;  /* Adjust position as needed */
    left: 10px; /* Adjust position as needed */
    width: 24px;  /* Adjust size as needed */
    height: 18px; 
    margin-bottom: 40px;
    background-image: url('/public/img/arrow-blue.svg');
    background-size: cover; /* Or 'contain' based on your need */
  }


  .card-style-red-arrow{
    border: none;
    text-align: left;/* Adjust padding as needed */
    padding: 20px;
    padding-left: 10px;
    padding-top: 34px;
    position: relative;
  }
  
  .card-style-red-arrow:after {
    content: "";
    position: absolute;
    top: 10px;  /* Adjust position as needed */
    left: 10px; /* Adjust position as needed */
    width: 24px;  /* Adjust size as needed */
    height: 18px; 
    margin-bottom: 40px;
    background-image: url('/public/img/arrow-red.svg');
    background-size: cover; /* Or 'contain' based on your need */
  }


  .leading-people-description {
    width: 100%;
  }

}
/* large */
@media screen and (max-width: 1199px) {

  .card-grid-container {
    border: 5px solid #3783B2;
    padding-top: 30px;
    padding: 30px;
  }

  .get-started-here {
    padding-left: 200px;
    margin: 0px;
    margin-bottom: 30px;
    background-image: url('/public/img/skinny-alert.svg');
    background-size: cover; /* Or 'contain' based on your need */
    height: 10px;
  }
  .get-started-here h3{
    font-size: 1.7rem;
  }


}
/* x-large */
@media screen and (max-width: 1399px) {
  .product-support {
    display: none;
  }

  .get-started-here {
    margin: 0px;
    margin-bottom: 30px;
    padding-left: 200px;
    background-image: url('/public/img/skinny-alert.svg');
    background-size: cover; /* Or 'contain' based on your need */
    height: 100px;
  }
  .get-started-here h3{
    font-size: 1.7rem;
  }
}
/* xx-large */

@media screen and (max-width: 3000px) {  
  .get-started-here {
    padding-left: 200px;
    margin: 0px;
    margin-bottom: 30px;
    background-image: url('/public/img/skinny-alert.svg');
    background-size: cover; /* Or 'contain' based on your need */
    height: 100px;
  }
  .get-started-here h3{
    font-size: 1.7rem;
  }
}

/* /////////////////////////////////// */
/* x-small */
@media screen and (min-width: 576px) {
  
}


/* small */
@media screen and (min-width: 767px) {

  
}
/* medium */
@media screen and (min-width: 991px) {
  .App {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px
  }


  .navbar {
    display: none;
  }
}



/* large */
@media screen and (min-width: 1199px) {


}
/* x-large */
@media screen and (min-width: 1399px) {
  .ps-offcanvas {
    display: none;
  }
}
/* xx-large */
@media screen and (min-width: 3000px) {

}
/* /////////////////////////////////// */

  

